* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Pretendard', sans-serif;
}

/* canvas{display: contents;}/ */
html {
    font-size: 75%;
    overflow: hidden;
    scroll-behavior: smooth;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block
}

html, body, h1, h2, h3, h4, h5, h6, div, p, blockquote, pre, code, address, ul, ol, li, menu, nav, section, article, aside, dl, dt, dd, table, thead, tbody, tfoot, label,
caption, th, td, form, fieldset, legend, hr, input, button, textarea, object, figure, figcaption {
    margin: 0;
    padding: 0;
    border: 0 none
}

body{
    background-color: #3a3b42;
    width: 100%;
    -webkit-text-size-adjust: none;
    word-wrap: break-word;
    word-break: break-all;
    height: 100%;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}

body, input, select, textarea, button {
    border: none;
    font-size: 1.1rem;
    font-family: 'Pretendard', 'Malgun Gothic', 'Dotum', 'Gulim', 'Tahoma', 'Verdana', sans-serif;
    color: #777;
    font-weight: 500
}

input {
    font-family: 'Pretendard', 'Malgun Gothic', 'Dotum', 'Gulim', 'Tahoma', 'Verdana', sans-serif;
    font-weight: 500
}
input:disabled{
    cursor: not-allowed;
}
header, h1 {
    font-size: 100%
}

fieldset {
    border: 0 none !important;
}

html, body {
    height: 100%;
    overflow-y: hidden;
}

main, header, section, nav, footer, aside, article, figure {
    display: block
}

div, p, span, li, table td {
    word-break: keep-all
}

caption, legend {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    font-size: 0;
    line-height: 0
}

/* img {border:none;vertical-align:top;width:100%; height: 100%; } */
select, input {
    vertical-align: middle
}

hr {
    display: none;
    border: 0 none
}

ol, ul, ul li {
    list-style: none;
    margin-bottom: 0;
}

address, cite, code, em {
    font-style: normal;
    font-weight: normal
}

label {
    cursor: pointer
}

button {
    border: 0;
    cursor: pointer;
    background: none;
}
button:disabled{cursor: not-allowed;}

h1, h2, h3, h4 {
    font-weight: bold;
}

/* link */
a {
    color: #000;
    text-decoration: none
}

/*링크 기본*/
a:link, a:visited {
    text-decoration: none
}

a:hover, a:active, a:focus {
    text-decoration: underline
}


/* table */
table {
    width: 100%;
    border-collapse: collapse;
    -webkit-user-select: text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
}

/*테이블 기본*/
table, th, td {
    border-spacing: 0;
    border: 0 none;
}
