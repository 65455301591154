.pd0 {padding: 0 !important;}
.fl41{flex: 1 1 41%;}
.manage-layout{width: 100%; height: 100%; padding :0 !important;}
.manage-layout .table_type1 {height: calc(100% - 37px)}
.admin_wrap_col{width: 100%; height: 100%; display: flex; flex-direction: column; padding:20px; gap: 10px;}
.admin_wrap_col .admin_wrap{padding:0px}
.admin_wrap{padding:20px;width:100%;height:100%;position:relative;display:flex;flex-direction:row;gap:15px; overflow: hidden;}
.admin_wrap h3{color:#333; line-height: 28px; font-size:14px; font-weight: 600;}
.admin_wrap h3.h3_type2{color:#333; line-height: 28px; font-size:20px;font-weight:200}
.admin_wrap .button_basic{display:flex;align-items: center;flex-direction:row;justify-content:space-between;align-items:center;gap: 5px;}
.admin_wrap .rowCount{font-weight: 500; font-size: 13px; margin: 0px;}
.title_count{display: flex;    align-items: baseline; gap: 5px; }
.title_count span{font-size: 11px; color: #379aea;}
.form_button_group{display: flex; justify-content: space-between; flex-direction: row-reverse}
/* .admin_wrap .button_basic h4{margin: 0;} */
.admin_wrap .button_basic .hidden_input{display: none;}
.admin_wrap .image_error{font-weight: 600;color: #f26e6e; font-size: 11.5px;}
.admin_wrap .button_basic .upload_button{padding:0 10px; width: fit-content;background:#83b2e6;color:#fff;height:28px;line-height:28px; display: inline-block; white-space: nowrap;}
.admin_wrap .button_basic button{border-radius:5px;padding:0 15px;background:#282f3e;color:#fff;height:30px;line-height:30px;}
.admin_wrap .button_basic button:disabled{background: #d9d9d9;color: #7f8c8d;}
.admin_wrap .button_basic button.delete{background:#ff2d55;}
.admin_wrap .button_basic button.delete:disabled{background:#ffb2b2; color: #f9f9f9;}
.admin_wrap .button_basic button.save{background:#6476f5;}
.admin_wrap .button_basic button.save:disabled{background:#acbcff; color: #f9f9f9}
.admin_wrap .hospital_user_wrap{flex:1 1 20%;height:100%;}
.admin_wrap .button_wrap{width:100%; }
.admin_wrap .button_wrap button{background:#1fcf9b;}
.admin_wrap .list_items{width:100%;height:100%;overflow:scroll;background:#fff;border:1px solid #ddd;}
.admin_wrap .list_items .list_item{display:flex;flex-direction:row;justify-content:space-between;padding:10px;border-bottom:1px dotted #eee;align-items:center;}
.admin_wrap .list_items .list_item:hover{background:#f2f2f2;}
.admin_wrap .list_items .list_item .item_title{}
.admin_wrap .list_items .list_item .item_button{}
.admin_wrap .list_items .list_item .item_button button{}
.admin_wrap .list_none{height:100%;width:100%;background:#f0f0f0;border:1px solid #e2e2e2;display:flex;flex-direction:column;align-items:center;justify-content:center;}
.admin_wrap .list_none .none_content{color:#000;font-weight:700;font-size:16px;}
.admin_wrap .list_none .none_button{width:90%;margin:20px;justify-content:center;align-items:center;}
.admin_wrap .list_none .none_button button{}
.admin_wrap .hospital_user_wrap{display:flex;flex-direction:column;}
.admin_wrap .hospital_user_wrap .hospital_list{flex:1 1 20%;height:100%;width:100%;}
.admin_wrap .hospital_user_wrap .doctor_list{flex:1 1 20%;width:100%;height:100%;display: flex;flex-direction: column;}
/* .admin_wrap .content_form{flex: 1 1 41%; }  */
.admin_wrap .content_form{border-left:1px solid #aaa;padding-left:15px; height: 100%;}
.input_button{}
.input_button button{flex:1 1 10%;}
.input_button .input_wrap{flex:1 1 90%;}
.input_group{display:flex;flex-direction:row;gap:30px;}
.input_group .input_wrap{width:50%;}
.input_group .input_wrap .input_label{flex-basis: 28%;}
.input_wrap{display:flex;margin-bottom:8px;padding:0;align-items:center;gap:10px;}
.input_wrap .input_label{font-weight:600; flex-basis: 13.3%;}
.input_wrap span.essential_value{font-size: 13px; color: #ff2d55;}
.input_wrap .input_box{flex:1;}
.input_wrap .input_box input, .input_wrap p.fake_input{width:100%;height:30px; line-height: 30px;resize:none;border:1px solid #ddd;padding:0 6px; font-size: 12px;}
.input_wrap .input_box input:disabled, .input_wrap p.fake_input{background: #e7e7e7; cursor: not-allowed;}
.input_wrap .input_box textarea{width:100%;resize:none;border:1px solid #ddd;height: 95px; padding: 6px;}
.form_button{height:40px;display:flex;flex-direction:row;align-items:center;justify-content:flex-end!important;margin:0!important;}
.form_button button{padding:0 15px;background:#000;color:#fff;height:30px;line-height:30px;}

.input_post .post_num{flex:none; flex-basis: 15%;}
.input_post button.search{width:100px!important;flex:none;}

/* 센터 관리 */
#center_wrap .center_label{background: rgb(190, 206, 221); padding: 8px; flex: 1;}
.center_form{border-radius:10px;width: 100%; height:100%; padding:25px 20px;background:#fff;border:1px solid #ddd; display: flex; flex-direction: column; gap: 15px;}
.center_form.disable{background:#efefef;}
.center_form.disable .input_wrap .input_box input,.center_form.disable .input_wrap .input_box p.fake_input{background: #c9c9c9;color: #7e7e7e;}
.center_form h4{font-size: 13px;color: #333;margin-bottom: 10px;}
.center_form .title_group{display: flex;justify-content: space-between; align-items: center; margin-bottom: 5px;}
.center_form .title_group h4{margin: 0; }
.center_form .title_group button{border-radius: 5px;color: #fff;padding: 5px 10px;height: 30px; background: #1fcf9b;}
.center_form .title_group button:disabled{color: #7f8c8d;background: #d9d9d9;}

    

.center_list:last-child{margin-bottom: 0;}

.flex_list_content{flex:1}
#center_wrap{width: 100%;height: 100%;display: flex;flex-direction: column;}
#center_wrap .title_wrap h3{line-height:34px;}
#center_wrap .hospital_list .list_items{height: calc(100% - 33px);}
#center_wrap .doctor_list{height: 100%;display: flex;flex-direction: column; gap: 10px;}
#center_search_group #customSelect .custom-select-options.open li:hover {background: #aab9c9;}
.input_box .btn_selected{min-width: 40px;padding: 4px 8px;background: #7cdbaa;color:#fff;font-size:12px;display:flex;align-items:center; cursor: pointer;}
.input_box .btn_selected svg{ width: 12px; height: 12px; margin-left: 3px;}
/* .admin_wrap .center_list{flex: 1 1 20%;}
 */
.admin_wrap .doctor_list{flex: 1;}
.center_content{border-bottom:1px solid #d2dae3; background:#dde8f3; display: flex; padding: 7px; justify-content: center; gap:5px; width: 100%;color: #000000;}
.center_content .search_group{display: flex; align-items: center;width:400px;gap: 5px; height: 34px; border-radius: 5px; background-color: #becedd;}
.center_content .search_group .custom-select-selected{background: #becedd; padding: 8px;}
.center_content .search_group .custom-select-options li:hover{background: #c9d1d8;}
.center_content .button_group{display: flex; gap: 5px;}
.center_content .button_group button{border-radius:8px;padding: 0 10px;background:#282f3e;color: #fff;height: 34px; line-height: 34px}
.center_content .button_group button:disabled{background: #282f3e87;color: #d1d1d1;}

/* 판독료 관리 */
#pay_admin_wrap{width: 100%; height: 100%; display: flex; flex-direction: column;padding:20px}
#pay_admin_wrap .hosplist_search{flex: 0 1 20%; height: 100%;gap:10px}
#pay_admin_wrap .admin_content_wrap{flex: 1 1;display: flex;flex-direction: column; gap: 10px;}
#pay_admin_wrap .button_basic h3{line-height: 30px;}
.paytable_wrap{display: flex; flex-direction: column; gap: 10px;}
.paytable_wrap.unregistered{height: 45%;}
.paytable_wrap.registered{height: 55%;}
span.warning_text{color: #ff2d55; font-size: 11px; line-height: 20px;}
#pay_admin_wrap .table_type1 > div.table_box{height: 100%;}

.hosplist_search .admin_wrap .button_wrap{margin-top: 5px;}
#center_wrap .search_center_group ul{height: 120px; }
/* .hosplist_search .search_center_group{margin-bottom: 5px;} */

/* 병원 관리 */
#center_wrap .hospital_list{display: flex;}
#center_wrap .hospital_list .hosplist_search{flex: 1;}
/* 우편 검색 */
.postForm{display:flex; justify-content: center; width:600px; height: 635px; position: relative; background: #ececec;}
/* 병원 찾기  */
.modal_form_background{position: fixed;inset: 0px;background-color: rgba(0, 0, 0, 0.5);display: flex;justify-content: center;align-items: center;z-index: 100;}
.findmodal_form{width: 600px; height: 635px; display: flex; flex-direction: column;background: #ececec; border-radius: 5px;}
.modal_form_titlebar{height: 40px; width: 100%; display: flex;justify-content: space-between; align-items: center; padding: 6px 15px; background-color: #343a4c;    border-top-left-radius: 5px;border-top-right-radius: 5px;}
.modal_form_titlebar h4{font-size: 15px;margin: 0; color: #fff; display: flex; align-items: center; }
.modal_form_titlebar h4 svg{height: 20px;width: 18px;}
.modal_form_titlebar button{background:none;}
.modal_form_titlebar .btn_exit{width: 20px !important; height: 20px !important; padding: 0!important; background: none !important; color: #434343!important; flex: none !important;}
.modal_form_titlebar .btn_exit svg{width: 20px; height: 20px; fill: #fff;}

.modal_form_content{width: 100%; flex:1; padding: 15px;  }
.find_hospital_list{height: calc(100% - 40px);/* .modal_form_titlebar 높이 빼기  */ display: flex; flex-direction: column;}
.modal_form_content p {display: flex;align-items: center;border: 1px solid #dcdcdc;padding: 6px 12px;border-radius: 4px;background-color: #ffffff;}
.modal_form_content input {flex: 1;border: none;outline: none;font-size: 16px;width: 100%;height: 40px; }
.modal_form_content input::placeholder {color: #b1b1b4;}
.modal_form_content ul {border: 1px solid #dcdcdc;border-radius: 4px;overflow-y: auto;background-color: #ffffff;padding: 0;list-style: none;}
.modal_form_content ul li {display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;padding: 8px 12px;border-bottom: 1px solid #ececec;font-size: 13px;color: #333;cursor: pointer;gap: 2px;}
.modal_form_content ul li:hover {background-color: #f0f0f0;}
.modal_form_content ul li:last-child {border-bottom: none;}
.modal_form_content ul li span.emphasis_label{font-size: 14px;color: #333;font-weight: bold;}
.modal_form_content ul li span{font-size: 12px;color: #575757;}
.modal_form_content .center_form{padding: 15px;}
.modal_form_content .center_form .input_group .input_wrap .input_label{flex-basis: 33%;}
.modal_form_content .center_form .input_post .input_wrap .input_label{flex-basis:15%;}

#doctor_select .selector{height: 30px;display: flex;align-items: center;justify-content: space-between;border: 1px solid #ddd;/* padding: "0 10px"; */cursor: pointer;background-color: #fff;}
#doctor_select .dropdown{position: absolute;width: 100%;border: 1px solid #ddd;/* border-radius: 6px; */margin-top: 1px;max-height: 200px;overflow-y: auto;background-color: #fff;z-index: 10;box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);}
#doctor_select .list_item{padding: 4px 8px 4px 16px; font-size: 12px;}
#doctor_select .list_item.active, #doctor_select .list_item:hover{background-color: #f0f8ff;}
#doctor_select .group{margin-bottom: 3px;}
#doctor_select .groupHeader{font-size: 13px;font-weight: bold; padding: 5px 7px; cursor: pointer; background-color: #f9f9f9;}

/* 유저 관리 */
.admin_search_group{display: flex; align-items: center; gap: 5px;}
.admin_search_inputs{width: 280px; height: 34px; border:none;background:#eaebef; display: flex; align-items: center;border-radius:7px;}
.admin_search_input{ padding: 5px;flex: 1; font-size: 12px;background:#eaebef;;}
.admin_search_group span{font-size: 11px;}
.tab_container{display: flex;margin-bottom: 10px; border-bottom: 1px solid #ddd;}
.tab_container .btn_tab{ padding: 8px 16px; cursor: pointer}
.tab_container .btn_tab.active{ font-weight: bold; border-bottom: 3px  solid #6c73a9;}
.admin_wrap .user_table_container{width: 100%; height: 100%;}
.admin_wrap .user_table{flex-basis: 55%;display: flex;flex-direction: column;gap:5px}
.admin_wrap .user_form{flex: 1;}

/* 수수료 관리 */
/* #commision_page .flex-item:nth-child(1), #commision_page .flex-item:nth-child(2){flex-basis: 30%; flex-shrink: 0;}
#commision_page .flex-item:nth-child(3){flex: 1; flex-shrink: 0;} */
#commision_page .admin_wrap > .flex-item{display: flex; }
#commision_page .admin_wrap > .flex-item:nth-child(1){flex-direction: column; gap: 15px;flex-basis: 50%;}
#commision_page .admin_wrap > .flex-item:nth-child(2){flex: 1;}
#commision_page .table_type1{height: 100%;}
.contract_list{display: flex; gap: 10px; flex-direction: column;flex-basis: 35%;}
.commision_content{display: flex;flex-direction: column;gap:10px}
.commision_content .button_wrap{margin:0}
.query-builder{border: 1px solid #ddd; border-radius: 5px;}
.table-header, .query-row {display: flex;gap: 10px; align-items: center;border-bottom: 1px solid #ccc;padding: 6px;}
.query-row:last-child{border-bottom: none;}
.table-header {font-weight: bold;padding: 10px;background-color: #f1f1f1; }
.table-body { max-height: 120px;overflow-y: auto;}
.header-cell{text-align: center; color: #333;}
.table-cell {flex: 1;padding: 0px;text-align: center; display: flex; align-items: center;justify-content: center;}
/* .table-cell input, .table-cell select { width: 100%; padding: 5px; border: 1px solid #ccc; border-radius: 4px; background-color: #fff; height: 28px; color: #777; } */
.table-cell input, .table-cell select {height:27px;background:#fff;color:#333; width: 100%; padding: 3px; border: 1px solid #ccc; border-radius: 4px; font-size: 12px; }
.table-cell input{text-align: right;}
.table-cell input::placeholder{text-align: center;}
.table-cell input:disabled, .table-cell select:disabled {background: #ddd; color: #a4a4a4; cursor: not-allowed;}
.table-cell span { margin-left: 3px; }
.table-cell button { border: none; color: #ff4d4f; cursor: pointer; display: flex; align-items: center; justify-content: center; transition: background-color 0.3s ease; }
.table-cell button svg { width: 16px; height: 16px; }
.table-cell button:disabled {color: #ccc;cursor: not-allowed;}
.table-cell button:disabled:hover {color: #ccc;cursor: not-allowed;}
.table-cell button:hover { color: #d9363e; }
.button-container { margin-top: 10px; text-align: right; }
.add-row {  padding: 5px 10px; border: none; background-color: #4caf50; color: white; cursor: pointer; border-radius: 5px; height: 30px; }
.add-row:hover { background-color: #45a049; }
.add-row:disabled { background-color: #d9d9d9; color: #7f8c8d;}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltip-text {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: left;
    padding: 8px;
    border-radius: 4px;
    width: 220px;
    position: absolute;
    z-index: 100;
    bottom: 125%; /* 위치 조정 */
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 1.4;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}