.mobile_wrap form > div{margin-bottom:10px;}
.mobile_wrap h3{color:#333;margin-right:10px}
.mobile_wrap .search{display:flex;gap:5px;align-items: flex-start; flex-direction: column;}
.mobile_wrap .search_box{flex: 1; position: relative;}
.mobile_wrap .search .search_box input{width: 100%;padding:8px; padding-left: 30px; border-radius:7px;background-color:#fff; border: 1.5px solid #cccdd3;background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23ccc'><path d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/></svg>") no-repeat 10px center #fff;   background-size: 14px 14px;}
.mobile_wrap .search .search_box input::placeholder{color: #a7a7a7; font-weight: 400;}
.input_search:focus,
.input_search:not(:placeholder-shown) {
  background-image: none;
}
.mobile_wrap .search input{width: 100%; padding:8px 4px; border-radius:7px;background:#eaebef}
.mobile_wrap .search_box{position: relative; flex: 1; width: 100%;}
.mobile_wrap .search_box > div{position: absolute;top: calc(100% + 2px); width: 100%;background: #fff;border-radius: 7px;border: 1px solid #eee;box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);}
.mobile_wrap .search_box .result_list ul{max-height: 200px; overflow-y: auto; padding: 4px; }
.mobile_wrap .search_box .result_list li{display: flex;justify-content: space-between;padding: 8px 10px;font-size: 13px;color: #777;cursor: pointer;font-weight: 500;}
.mobile_wrap .search_box .result_list li:hover, .mobile_wrap .search_box .result_list li:hover em{background: #f8f4ff;color: #6a37ff;border-radius: 7px;}
.mobile_wrap .search_box .result_list li em{ color: #555;font-weight: bold;}
.mobile_wrap .search_box .no_results{display: flex;flex-direction: column;align-items: center;padding: 20px;color: #777;gap: 5px;}
.mobile_wrap .search_box .no_results strong{font-weight: bold;color: #222;}

.mobile_wrap .comment h3{margin-bottom:10px}
.mobile_wrap .comment textarea{resize: none;width:100%;height:100px;padding:7px;border-radius:10px;background:#eaebef}
.mobile_wrap .file_upload button{margin-left:5px}
.mobile_wrap .file_upload button svg{width:20px;height:20px}
.mobile_wrap .file_upload .uploadbox{border:1px solid #ddd;border-radius:10px;padding:12px; max-height: 160px; overflow-y: auto;}
.mobile_wrap .file_upload .uploadbox input{margin-bottom:5px}
.mobile_wrap .file_upload .uploadbox .file_name{flex: 1}
.mobile_wrap .file_upload .uploadbox button:disabled{cursor: not-allowed;}
.mobile_wrap .file_upload .uploadbox button:disabled svg{fill:#b5b5b5}


.uploadbox div{display:flex;align-items: center;gap:10px;margin-bottom:5px}
.uploadbox input{position: absolute;left:0;top:0;width:1px;height:1px;z-index: -999}
.upload_label{border-radius:7px;color:#fff;background:#333;cursor: pointer;width: 90px;padding:7px 10px;display: flex;justify-content: center;align-items: center;}






