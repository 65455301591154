/*로그인*/
.Login_wrap {background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgb(62 62 75) 70%, rgb(44, 63, 91) 100%);width: 100%;height: 100vh;display: flex;align-items: center;justify-content: center;flex-direction: column;}
.Login_wrap h1 {margin-bottom: 50px;font-size: 35px;font-weight: 800;color: #ffffff;text-align: center;}
.login_box {width: 500px;margin: 0 auto;background-size: 150px;}
.login_box input {border: 1px solid #ddd;height: 50px;line-height: 50px;width: 100%;margin-bottom: 10px;}
.login_box input[type=checkbox] {width: 20px;height: 20px;float: left;border-radius: 3px;}
.Login_wrap:after{width:160px;height:50px;position: absolute;left:50%;bottom:20px;margin-left:-80px;content:'';background-image:url(../../../assets/image/user/logo_wh.png);background-size:120px; background-repeat:no-repeat; background-position: center;}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    -webkit-text-fill-color: #333 !important;
}
.login_box label {color: #4f4f4f;margin-left: 5px;line-height: 20px;}
.fd_pw {color: #383838;float: right;}
.btn_login {margin-top: 50px;font-size: 1.5rem;font-weight: 600;width: 100%;display: block;height: 55px;line-height: 55px;color: #fff;background: #4042c2;}

.sub_logo {background: #f2f2f2 url('../../image/user/logo_co.png') no-repeat center 56%;background-size: 150px;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;}
.sub_logo > a {font-size: 40px; color: #000;}
.sub_logo.hidden {display: none!important; width: 100%;height: 100%;}
.side_center {text-align: center;padding: 10px 0;color:white;font-size: 20px;}

.login_box{position: relative;background:#fff;padding:50px 30px;border-radius:10px;  box-shadow: 0 15px 25px rgba(0,0,0,.6);}
.login_box .user_box{position: relative}
.login_box .user_box input {
    width: 100%;
    padding: 10px 0;
    font-size: 12px;
    color: #333;
    margin-bottom: 30px;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    background: transparent;
}
.login_box .user_box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 12px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

@media screen and (max-width:500px) {
    .Login_wrap{padding:0 10px}
    .login_box{width:100%}
}