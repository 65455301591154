/* 로딩 화면 페이드 효과 (이동 없음) */

.loading-enter {
  opacity: 0;
}

.loading-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.loading-exit {
  opacity: 1;
}

.loading-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
